import axios from "axios"
var BaseURL = axios.create({
	baseURL:'https://vrgame.qitianhy.com/api',
	// baseURL:'https://tphp.qitianhy.com/api',
	timeout: 5000,
})
let mainUrl=function(url){
	return  'https://vrgame.qitianhy.com/'+url
	// return  'https://tphp.qitianhy.com/'+url
}
export{
	BaseURL,mainUrl
}
