import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: "/imp",
    name: "imp",
    component: () => import("@/views/index/Imp.vue"),
    children: [
      {
        path: "/user",
        name: "user",
        // component: () => import("@/views/index/User.vue"),
        component: () => import("@/views/user/User.vue"),
        beforeEnter: (to, from, next) => {
          next();
        },
      },
      {
        path: "/shop",
        name: "shop",
        component: () => import("@/views/shop/Shop.vue"),
        // component: () => import("@/views/index/mendian/Shop.vue"),
        beforeEnter: (to, from, next) => {
          next();
        },
      },
      {
        path: "/game",
        name: "game",
        // component: () => import("@/views/index/mendian/Game.vue"),
        component: () => import("@/views/shopGame/ShopGame.vue"),
      },
      {
        path: "/shebei",
        name: "shebei",
        component: () => import("@/views/equipment/Equipment.vue"),
      },
      {
        path: "/lunbo",
        name: "lunbo",
        component: () => import("@/views/index/mendian/Lunbo.vue"),
        // component: () => import("@/views/banner/Banner.vue"),
      },
      {
        path: "/partner",
        name: "partner",
        component: () => import("@/views/partner/Partner.vue"),
      },
      {
        path: "/caiwu",
        name: "caiwu",
        // component: () => import("@/views/index/Caiwu.vue"),
        component: () => import("@/views/financial/Financial.vue"),
      },
      {
        path: "/youxi",
        name: "youxi",
        // component: () => import("@/views/index/Youxi.vue"),
        component: () => import("@/views/allGame/AllGame.vue"),
      },
      {
        path: "/dingdan",
        name: "dingdan",
        component: () => import("@/views/orderList/OrderList.vue"),
      },
      {
        path: "/refund",
        name: "refund",
        component: () => import("@/views/refund/Refund.vue"),
      },
    ],
  },
];
const router = new VueRouter({
  routes,
});
//全局路由守卫
router.beforeEach((to, from, next) => {
  let login = JSON.parse(localStorage.getItem("userInfo"));
  if (to.name === "login") {
    if (login) next();
  } else {
    if (login) next();
    else next("/");
  }
  next();
});

export default router;
