import axios from 'axios';
let baseURL='https://vrgame.qitianhy.com/api/'//正式
// let baseURL='https://tphp.qitianhy.com/api/'//测试
export default function myAxios(axiosConfig) {
  const service = axios.create({
    baseURL,
    timeout: 10000, // 设置统一的超时时长
  });

  return service(axiosConfig)
}
export{baseURL}