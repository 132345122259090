import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import axios from "axios";
import {BaseURL,mainUrl} from "./components/request/request.js"
import {baseURL} from "./apis/axios"
import ElementUI from 'element-ui';

import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { container, ImageExtend } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)

import 'element-ui/lib/theme-chalk/index.css';
//挂载到vue原型链上
Vue.prototype.$axios=BaseURL;
Vue.prototype.$mainUrl=mainUrl;
Vue.prototype.$baseURL=baseURL;
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  Vue,
  render: (h) => h(App),
}).$mount("#app");
